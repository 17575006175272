import React from 'react'

//components
import LinkCard from "./link-card"

const LinkCards = ({linkItems}) => {
    const linkCards = linkItems.map((link)=> (<LinkCard key={link.node.title} link={link.node}/>));
    
    return(
        <ul className="cards cards-links">
            {linkCards}
        </ul>
    )
}

export default LinkCards
