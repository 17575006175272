import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'react-bootstrap'

//components
import Layout from '../components/layout'
import SEO from "../components/seo"
import CollectionCards from '../components/collection-cards'
import LinkCards from '../components/link-cards'

const TagArchiveTemplate = ({data}) => {
    const tagData = data.contentfulTag;

    return(
        <Layout>
            <SEO title={tagData.tag} />
            <section className="section">
                <Container fluid>
                    <div className="section-intro">
                        <h1 className="heading page-heading">Resultaten voor "{tagData.tag}" <small>({(data.allContentfulCollection.edges.length + data.allContentfulLink.edges.length)})</small></h1>
                    </div>
                </Container>
            </section>
            <section className="section">
                <Container fluid>
                    <div className="section-intro">
                        <h2 className="heading section-heading">Links<small> ({data.allContentfulLink.edges.length})</small></h2>
                    </div>
                    <div class="section-content">
                        <LinkCards linkItems={data.allContentfulLink.edges}/>
                    </div>
                </Container>
            </section>
            <section className="section">
                <Container fluid>
                    <div className="section-intro">
                        <h2 className="heading section-heading">Collecties<small> ({data.allContentfulCollection.edges.length})</small></h2>
                    </div>
                    <div class="section-content">
                        <CollectionCards collectionItems={data.allContentfulCollection.edges}/>
                    </div>
                </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        contentfulTag( slug: { eq: $slug }) {
            tag
        }
        allContentfulCollection(filter: {tags: {elemMatch: {slug: {eq: $slug}}}}) {
            edges {
              node {
                slug
                title
                image {
                  fluid(maxWidth:400, maxHeight:400){
                    ...GatsbyContentfulFluid
                  }
                }
                tags {
                    tag
                    slug
                }
              }
            }
        }
        allContentfulLink(filter: {tags: {elemMatch: {slug: {eq: $slug}}}}) {
            edges {
              node {
                title
                description {
                    description
                }
                url
                image {
                    fluid(maxWidth:400, maxHeight:400){
                        ...GatsbyContentfulFluid
                    }
                    title
                }
                type {
                    identifier
                    type
                    slug
                }
                partner
                tags {
                    tag
                    slug
                }
              }
            }
        }
    }
`
export default TagArchiveTemplate
